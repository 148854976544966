<template>
  <ciam-card-content-line type="full-width">
    <template v-slot:content>
      <ciam-card>
        <div>
          <Promised :promise="loading">
            <template v-slot:pending>
              <ciam-loader class="py-4"></ciam-loader>
            </template>

            <template v-slot:rejected="problem">
              <ciam-alert v-if="(problem.type = 'not-available-on-current-plan')" :title="problem.title"
                :description="getUpsellAlertDescription()" :type="AlertStatus.WARNING_HREF"
                :href-text="$t('upsell.email.contact')" :href="getUpsellAlertHref()" />
            </template>

            <template>
              <ciam-card-content-line type="full-width">
                <template v-slot:content>
                  <b-table style="clear: both" :striped="true" :hoverable="true" :data="allInstances">
                    <b-table-column field="fullName" :label="$t('deploymentInstances.instances.fullName')"
                      v-slot="props">
                      <ciam-clipboard title="Copy instance Id" :payload="props.row.instanceId">{{ props.row.fullName }}
                      </ciam-clipboard>
                      <ciam-text class="ml-4 tag is-success " v-if="props.row.isLive">
                        {{ $t('deploymentInstances.instances.isLive') }}
                      </ciam-text>

                    </b-table-column>
                    <b-table-column field="provider" :label="$t('deploymentInstances.instances.syncGroup')"
                      v-slot="props">
                      <b-tooltip :label="props.row.syncGroupId" type="is-info" position="is-top">
                        <ciam-text class="tag is-success" v-if="props.row.syncGroupRole === 'LEADER'">
                          {{ props.row.syncGroupRole.toLowerCase() }}
                        </ciam-text>
                        <ciam-text class="tag is-warning" v-if="props.row.syncGroupRole === 'FOLLOWER'">
                          {{ props.row.syncGroupRole.toLowerCase() }}
                        </ciam-text>
                      </b-tooltip>
                    </b-table-column>
                    <b-table-column field="provider" :label="$t('deploymentInstances.instances.provider')"
                      v-slot="props">
                      <ciam-text>{{ props.row.provider }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('deploymentInstances.instances.region')" v-slot="props">
                      <ciam-text>{{ props.row.region }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('deploymentInstances.instances.keycloakVersion')"
                      v-slot="props">
                      <ciam-text>{{ props.row.keycloakVersion }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="region" :label="$t('deploymentInstances.instances.branch')" v-slot="props">
                      <ciam-text>{{ props.row.branch }}</ciam-text>
                    </b-table-column>
                    <b-table-column field="status" :label="$t('deploymentInstances.instances.status')" v-slot="props">
                      <div class="flex flex-row gap-2 justify-stretch items-center">
                        <status-badge :status="props.row.status" />
                        <b-tooltip :label="$t('deploymentInstances.tooltip.status')" type="is-info" position="is-top">
                          <ciam-button class="mr-4 h-8 w-8"
                            @click="statusModal.isOpened = true, currentInstance = { instanceId: props.row.instanceId, status: props.row.status, name: props.row.fullName }">
                            <ciam-icon name="fa-edit" />
                          </ciam-button>
                        </b-tooltip>
                      </div>
                    </b-table-column>
                    <b-table-column field="live" v-slot="props">
                      <b-tooltip :label="$t('deploymentInstances.tooltip.promote')" type="is-info" position="is-top">
                        <ciam-button :disabled="props.row.isLive || props.row.status === 'DELETING'"
                          class="mr-4 h-8 w-8" @click="promoteInstanceLive(props.row.instanceId)">
                          <ciam-icon name="fa-play" />
                        </ciam-button>
                      </b-tooltip>
                      <b-tooltip :label="$t('deploymentInstances.tooltip.redeploy')" type="is-info" position="is-top">
                        <ciam-button class="mr-4 h-8 w-8"
                          @click="redeployModal.isOpened = true, currentInstance = { instanceId: props.row.instanceId }">
                          <ciam-icon name="fa-sync-alt" />
                        </ciam-button>
                      </b-tooltip>
                      <b-tooltip :label="$t('deploymentInstances.tooltip.restore')" type="is-info" position="is-top">
                        <ciam-button class="mr-4 h-8 w-8"
                          :disabled="props.row.status !== 'RUNNING'"
                          @click="restoreParametersModal.isOpened = true, currentInstance = { instanceId: props.row.instanceId, name: props.row.fullName }">
                          <ciam-icon name="fa-database" />
                        </ciam-button>
                      </b-tooltip>

                      <b-tooltip :label="$t('deploymentInstances.tooltip.delete')" type="is-info" position="is-top">
                        <ciam-button :disabled="props.row.isLive || props.row.status === 'DELETING'" class="h-8 w-8"
                          @click="deleteInstanceConfirmationModal.isOpened = true, currentInstance = { instanceId: props.row.instanceId, name: props.row.fullName }">
                          <ciam-icon class="text-red-500" name="fa-trash" />
                        </ciam-button>
                      </b-tooltip>
                    </b-table-column>

                    <template #empty>
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>{{ $t('deployment.deploymentInstances.emptyList') }}</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </template>
              </ciam-card-content-line>
            </template>
          </Promised>
        </div>
      </ciam-card>
      <b-modal :active.sync="redeployModal.isOpened" has-modal-card trap-focus :destroy-on-hide="false"
        aria-role="dialog" aria-modal>
        <standard-modal :buttons="redeployModal.buttons" :item="currentInstance" @result="redeployInstance()">
          <template v-slot:header>{{ $t('deploymentInstances.redeploy.header') }}</template>
          <template v-slot:default>
            <div>
              <ciam-select :options="redeployActions" v-model="redeployMode"></ciam-select>
            </div>
          </template>
        </standard-modal>
      </b-modal>
      <b-modal :active.sync="statusModal.isOpened" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
        aria-modal>
        <standard-modal :buttons="statusModal.buttons" :item="currentInstance" @result="setInstanceStatus()">
          <template v-slot:header>{{ $t('deploymentInstances.status.header') }}</template>
          <template v-slot:default>
            <div>
              <ciam-select :options="status" v-model="currentInstance.status"></ciam-select>
            </div>
          </template>
        </standard-modal>
      </b-modal>
      <b-modal :active.sync="deleteInstanceConfirmationModal.isOpened" has-modal-card trap-focus
        :destroy-on-hide="false" aria-role="dialog" aria-modal>
        <standard-modal :buttons="deleteInstanceConfirmationModal.buttons" :item="currentInstance"
          @result="deleteInstance()">
          <template v-slot:header>{{ $t('deleteInstanceConfirmationModal.confirmRemoval') }}</template>
          <template>
            <p>
              <b>{{ $t('deleteInstanceConfirmationModal.confirmRemovalQuestion') }}</b>
            </p>
            <hr />
            <p>{{ $t('deleteInstanceConfirmationModal.confirmRemovalQuestionBis') }}</p>
            <hr />
            <p style="padding-top: 30px">
              {{ $t('deleteInstanceConfirmationModal.name') }}: {{ currentInstance.name }}
              <ciam-input type="text" v-model="deleteInstanceConfirmationModal.nameConfirmation"
                :placeholder="currentInstance.name" />
            </p>
          </template>
        </standard-modal>
      </b-modal>

      <b-modal :active.sync="restoreParametersModal.isOpened" has-modal-card trap-focus :destroy-on-hide="false"
        aria-role="dialog" aria-modal>
        <standard-modal :buttons="restoreParametersModal.buttons" :item="deployment" @result="restoreInstance()">
          <template v-slot:header>{{ $t('deploymentInstances.restore.header') }}</template>
          <template v-slot:default>
            <ciam-card>
              <ciam-card-content-lines>
                <ciam-card-content-line class="flex flex-row items-center">
                  <template v-slot:label>{{
                    $t('deploymentInstances.restore.triggerLastMinuteBackup')
                  }}</template>
                  <template v-slot:content>
                    <div class="grid grid-cols-2 gap-2">
                      <ciam-checkbox v-model="restoreParametersModal.parameters.bootstrap.triggerLastMinuteBackup"/>
                    </div>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line class="flex flex-row items-center">
                  <template v-slot:label>
                    <ciam-text>{{ $t('deploymentInstances.restore.selectInstance') }}</ciam-text>
                  </template>
                  <template v-slot:content>
                    <ciam-select @change="getLastInstanceBackups()" :options="getInstanceToRestoreOptions()"
                      v-model="restoreParametersModal.parameters.bootstrap.restoreFrom" data-e2e="instances-select" />
                  </template>

                </ciam-card-content-line>
                <ciam-card-content-line>
                  <template v-slot:label>
                    <ciam-text>{{ $t('deployment.advanced.backupList.title') }}</ciam-text>
                  </template>
                  <template v-slot:content>
                    <div class="relative overflow-x-auto">
                      <ciam-restore-backup-table @update-backup="handleBackupUpdate"
                        :currentInstanceId="currentInstanceToRestoreId" :backups="backups"
                        :isDisabled="restoreParametersModal.parameters.bootstrap.triggerLastMinuteBackup" />
                    </div>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line class="flex flex-row items-center">
                  <template v-slot:label>{{
                    $t('deploymentInstances.restore.setLiveAutomaticallyOnSuccess')
                  }}</template>
                  <template v-slot:content>
                    <div class="grid grid-cols-2 gap-2">
                      <ciam-checkbox v-model="restoreParametersModal.parameters.onSuccess.setLive" />
                    </div>
                  </template>
                </ciam-card-content-line>
              </ciam-card-content-lines>
            </ciam-card>
          </template>
        </standard-modal>
      </b-modal>
    </template>
  </ciam-card-content-line>

</template>

<script>
import DeploymentService from '@/pages/deployments/DeploymentService';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert.vue';
import { makeQueryablePromise } from '@/util/promise';
import { Notification } from 'vue-notifyjs';
import CiamClipboard from '@/components/CiamClipboard.vue';
import CiamSelect from '@/components/CiamSelect.vue';
import StandardModal from '@/components/StandardModal.vue';
import CiamInput from '@/components/CiamInput.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import CiamCheckbox from '@/components/themes_templates/CiamCheckbox.vue';
import CiamRestoreBackupTable from '@/components/CiamRestoreBackupTable.vue';

export default {
  name: 'DeploymentConfigurations',
  components: { CiamCheckbox, StatusBadge, CiamInput, StandardModal, CiamSelect, CiamClipboard, CiamAlert, CiamRestoreBackupTable },
  data() {
    return {
      backups: [],
      problem: null,
      loading: makeQueryablePromise(Promise.reject()),
      AlertStatus: AlertStatus,
      deployment: null,
      standbyInstances: [],
      liveInstance: null,
      allInstances: null,
      redeployMode: 'complete',
      currentInstance: {},
      currentInstanceToRestoreId: null,
      deleteInstanceConfirmationModal: {
        isOpened: false,
        nameConfirmation: '',
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('actions.delete'),
            classes: ['is-danger', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
      restoreParametersModal: {
        isOpened: false,
        parameters: {
          bootstrap: {
            triggerLastMinuteBackup: false,
            restoreFrom: null,
            restoreFromFile: null
          },
          onSuccess: {
            setLive: false,
          },
        },
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('actions.save'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
      redeployModal: {
        isOpened: false,
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('deploymentInstances.redeploy.action'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
      statusModal: {
        isOpened: false,
        buttons: [
          { text: this.$t('actions.cancel') },
          {
            text: this.$t('deploymentInstances.status.action'),
            classes: ['is-info', 'is-outlined'],
            returnVal: true,
          },
        ],
      },
    };
  },
  watch: {
    'restoreParametersModal.parameters.bootstrap.restoreFrom': function (
      newValue,
      oldValue
    ) {
      if (this.restoreParametersModal.parameters.bootstrap.restoreFrom) {
        this.currentInstanceToRestoreId = this.instanceOptions.find(instance => instance.value == this.restoreParametersModal.parameters.bootstrap.restoreFrom).id;
        this.getLastInstanceBackups();
      }

    },
  },
  mounted() {
    this.getInstances();
  },
  methods: {
    handleBackupUpdate(currentBackup) {
      this.restoreParametersModal.parameters.bootstrap.restoreFromFile = currentBackup;
    },
    getInstanceToRestoreOptions() {
      return this.instanceOptions;
    },
    async getLastInstanceBackups() {
      this.backups = await DeploymentService.getLastBackups(this.currentInstanceToRestoreId);
    },
    resetRestoreParametersModal() {
      this.restoreParametersModal.parameters = {
        bootstrap: {
          triggerLastMinuteBackup: false,
          restoreFrom: null,
        },
        onSuccess: {
          setLive: false,
        },
      };
    },
    restoreInstance() {
      if (!confirm('Are you sure you want to restore ?')) {
        return;
      }
      Object.keys(this.restoreParametersModal.parameters).forEach((k) =>
        !this.restoreParametersModal.parameters[k] ? delete this.restoreParametersModal.parameters[k] : null
      );

      DeploymentService.restoreInstance(this.currentInstance.instanceId, this.restoreParametersModal.parameters)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.restore.success'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.restore.failure'),
          });
          return null;
        })
        .finally(() => {
          this.resetRestoreParametersModal();
        });
    },
    getInstances() {
      this.loading = DeploymentService.getInstances(this.$route.params.id)
        .then(instances => {
          // Store details of standby instances
          const standbyPromises = instances.standbyInstanceIds.map(instanceId => {
            return DeploymentService.getInstance(instanceId)
              .then(instanceDetails => {
                return instanceDetails;
              })
              .catch(reason => {
                console.error('Failed to retrieve details of standby instance ', instanceId, ': ', reason);
                return null;
              });
          });

          // Store details of live instance
          const liveInstancePromise = DeploymentService.getInstance(instances.liveInstanceId)
            .then(instanceDetails => {
              instanceDetails.isLive = true;
              return instanceDetails;
            })
            .catch(reason => {
              console.error('Failed to retrieve details of live instance: ', reason);
              return null;
            });

          // Wait for all promises to resolve
          return Promise.all([liveInstancePromise, ...standbyPromises])
            .then(([liveInstance, ...standbyInstanceDetailsArray]) => {
              // Store details of live instance and standby instances in the same array
              this.allInstances = [liveInstance, ...standbyInstanceDetailsArray];
            });
        })
        .catch(reason => {
          console.error('Instances could not be retrieved: ', reason);
          throw reason;
        });
    },
    promoteInstanceLive(instanceId) {
      DeploymentService.promoteInstanceLive(instanceId)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.instances.successToPromote'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.instances.failedToPromote'),
          });
          return null;
        });
    },
    setInstanceStatus() {
      DeploymentService.setInstanceStatus(this.currentInstance.instanceId, this.currentInstance.status)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.instances.successToSetStatus'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.instances.failedToSetStatus'),
          });
          return null;
        });
    },
    redeployInstance() {
      DeploymentService.redeployInstance(this.currentInstance.instanceId, this.redeployMode)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.redeploy.success'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.redeploy.failure'),
          });
          return null;
        });
    },
    deleteInstance() {
      if (this.deleteInstanceConfirmationModal.nameConfirmation !== this.currentInstance.name) {
        alert(this.$t('deleteDeploymentConfirmationModal.mismatch'));
        this.deleteInstanceConfirmationModal.nameConfirmation = '';
        return false;
      }
      DeploymentService.deleteInstance(this.currentInstance.instanceId)
        .then(res => {
          Notification.notify({
            type: 'info',
            message: this.$t('deploymentInstances.instances.successToDelete'),
          });
          this.getInstances();
        })
        .catch(reason => {
          Notification.notify({
            type: 'danger',
            message: this.$t('deploymentInstances.instances.failedToDelete'),
          });
          return null;
        });
    },
  },
  computed: {
    instanceOptions() {
      return (this.allInstances || []).map(instance => ({
        value: instance.fullName,
        label: `${instance.fullName} - ${instance.status}`,
        id: instance.instanceId,
        disabled: instance.status !== 'RUNNING',
      }));
    },
    redeployActions() {
      return this.$t(`redeployActions`);
    },
    status() {
      return this.$t(`status`);
    },
  },
};
</script>
